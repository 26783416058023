'use client'

import { setCompanySector } from '@/app/globalRedux/filter/companyFilter'
import { AppDispatch, RootState } from '@/app/globalRedux/store'
import useLocalizedRouter from '@/app/hooks/useLocalizedRouter'
import useLoginRouter from '@/app/hooks/useLoginRouter'
import { useTranslation } from '@/app/i18n/client'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import Image from 'next/image'
import Link from 'next/link'
import { useParams } from 'next/navigation'
import { useDispatch, useSelector } from 'react-redux'

const Footer = () => {
  const { t } = useTranslation()
  const dispatch: AppDispatch = useDispatch()
  const params = useParams()
  const pushWithLocale = useLocalizedRouter()
  const userName = useSelector((state: RootState) => state.user.userName)
  const navigateToLogin = useLoginRouter()
  return (
    <div className="flex justify-center bg-footer_color mobile:mt-[0px] relative">
      <div className="flex flex-col  items-center w-[100%] px-[5%] py-[3%] mobile:width mobile:py-[8%] mobile:px-[2%]">
        <div className="w-[85%] flex flex-row text-[14px] gap-[6.5rem] mobile:w-[350px] items-start mobile:px-[10px]">
          <div className="block w-[100%]">
            <div className="flex flex-row w-[100%] justify-between text-[14px] gap-5 mobile:flex mobile:flex-col mobile:items-center mobile:justify-center">
              <div className=" flex flex-col justify-start !w-[250px] mobile:!w-max mobile:justify-center">
                <Link href="/">
                  <Image
                    loading="lazy"
                    width={173}
                    height={39}
                    src="/assets/image/logo.png"
                    alt=""
                    className="object-cover"
                  />
                </Link>
                <div className="w-[180px] flex mt-[12px] text-wrap mobile:text-center mobile:justify-center">
                  <p className=" mb-[9px] font-nunitosans_italic text-[12px]">
                    {t(
                      `${!userName ? 'Register now to join ' : ''}The world’s leading B2B website for fruit and vegetables.`
                    )}
                  </p>
                </div>
                {!userName && (
                  <div className="flex mt-5 mobile:mt-[10px] mobile:mb-2 mobile:justify-center">
                    <Button
                      variant="secondary"
                      size="sx"
                      className="w-max py-2 text-md cursor-pointer"
                      onClick={() => {
                        navigateToLogin('signup')
                      }}
                    >
                      {t('REGISTER YOUR COMPANY')}
                    </Button>
                  </div>
                )}
              </div>
              <div className="flex justify-around w-full mobile:flex-wrap mobile:gap-y-4 mobile:justify-between mobile:px-5">
                <ul className=" flex flex-col gap-y-2 items-start">
                  <li className="text-orange  text-[15px] font-ns_bold">
                    {t('Quick Links')}
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link
                      locale={false}
                      href={`/${params?.lng}/buyers-listing`}
                    >
                      {t('Buyers Alert')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link locale={false} href={`/${params?.lng}/sales-listing`}>
                      {t('Sales Offers')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link locale={false} href={`/${params?.lng}/news`}>
                      {t('News')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link locale={false} href={`/${params?.lng}/events`}>
                      {t('Events')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link
                      locale={false}
                      href={`/${params?.lng}/retails/news/1`}
                    >
                      {t('Retail Partners')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link locale={false} href={`/${params?.lng}/solutions`}>
                      {t('Solutions')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link locale={false} href={`/${params?.lng}/about-us`}>
                      {t('About Us')}
                    </Link>
                  </li>
                </ul>
                <ul className=" flex flex-col gap-y-2">
                  <li className="text-orange  text-[15px] font-ns_bold">
                    {t('Subscriptions')}
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link
                      locale={false}
                      href={`/${params?.lng}/solutions/for_buyers/`}
                    >
                      {t('Free Member')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link
                      locale={false}
                      href={`/${params?.lng}/solutions/for_buyers/`}
                    >
                      {t('Silver Member')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link
                      locale={false}
                      href={`/${params?.lng}/solutions/for_buyers/`}
                    >
                      {t('Gold Member')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link
                      locale={false}
                      href={`/${params?.lng}/solutions/for_buyers/`}
                    >
                      {t('Platinum Member')}
                    </Link>
                  </li>
                </ul>
                <ul className=" flex flex-col gap-y-2">
                  <li className="text-orange  text-[15px] font-ns_bold ">
                    {t('Industries')}
                  </li>
                  <li
                    className="cursor-pointer hover:text-orange hover:underline"
                    onClick={() => {
                      dispatch(setCompanySector('Fruit & Vegetable'))
                      pushWithLocale('/search/company-tab')
                    }}
                  >
                    {t('Fruits & Vegetables')}
                  </li>
                  <li
                    className="cursor-pointer hover:text-orange hover:underline"
                    onClick={() => {
                      dispatch(setCompanySector('Transport / Logistics'))
                      pushWithLocale('/search/company-tab')
                    }}
                  >
                    {t('Transports & Logistics')}
                  </li>
                  <li
                    className="cursor-pointer hover:text-orange hover:underline"
                    onClick={() => {
                      dispatch(setCompanySector('Pre - Post Harvest'))
                      pushWithLocale('/search/company-tab')
                    }}
                  >
                    {t('Pre - Post Harvest')}
                  </li>

                  <li
                    className="cursor-pointer hover:text-orange hover:underline"
                    onClick={() => {
                      dispatch(setCompanySector('Retail Chains'))
                      pushWithLocale('/retails/news')
                    }}
                  >
                    {t('Retail Chains')}
                  </li>
                </ul>
                <ul className=" flex flex-col gap-y-2">
                  <li className="text-orange  text-[15px] font-ns_bold">
                    {t('Advertise')}
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link
                      locale={false}
                      href={`/${params?.lng}/advertise_page/website_ad/ad_home_page/`}
                    >
                      {t('Website')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link
                      locale={false}
                      href={`/${params?.lng}/advertise_page/news_letter_ad/`}
                    >
                      {t('Newsletter')}
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-orange hover:underline">
                    <Link
                      locale={false}
                      href={`/${params?.lng}/advertise_page/print_directory/`}
                    >
                      {t('Print Directory')}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-2/12 mobile:w-full">
                <ul className="flex flex-col gap-y-2 w-full">
                  <li className="text-orange text-[15px] font-ns_bold mobile:text-center">
                    {t('Contact Us')}
                  </li>
                  <ul className="w-[143px] mobile:w-full flex flex-col gap-y-2 mobile:flex mobile:flex-row mobile:gap-[20px] mobile:justify-center">
                    <li className="flex gap-2 items-center">
                      <Image
                        loading="lazy"
                        width={14}
                        height={14}
                        src="/assets/Icons/callIcon.svg"
                        alt=""
                        className="w-[14px] h-[14px]"
                      />
                      +33 680 38 14 46
                    </li>
                    <li className="flex items-center gap-2">
                      <Image
                        loading="lazy"
                        width={14}
                        height={14}
                        className="w-[14px] h-[14px]"
                        src="/assets/Icons/mailIcon.svg"
                        alt=""
                      />
                      <a
                        className="cursor-pointer"
                        href="mailto:cd@fructidor.com?cc=support5@fructidor.com"
                      >
                        cd@fructidor.com
                      </a>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            <Separator className="h-[2px] bg-input mt-12 mb-4 mobile:mt-6" />
            <div className="w-[100%] flex justify-between items-center mobile:width mobile:flex-col mobile:text-center">
              <p className="mt-1 text-[14px]">
                {t('Copyright')} {new Date().getFullYear()} @ Fructidor.{' '}
                {t('All rights reserved')}
                <Link
                  locale={false}
                  href={`/${params?.lng}/terms_and_conditions`}
                  className="mx-2 text-primary font-ns_semibold underline pl-0.5"
                >
                  {t('Terms and Conditions')}
                </Link>
              </p>
              <div className="flex gap-4 mobile:mt-2">
                <label className="mt-1 text-[14px]">
                  {t('Follow us on social media')}
                </label>
                <Link
                  href="https://www.facebook.com/profile.php?id=100057561365581&mibextid=LQQJ4d"
                  target="_blank"
                >
                  <Image
                    loading="lazy"
                    style={{ display: 'inline' }}
                    width={26}
                    height={26}
                    src="/assets/Icons/facebook.svg"
                    alt=""
                  />
                </Link>
                <Link
                  href="https://www.linkedin.com/company/fructidor-com/"
                  target="_blank"
                >
                  <Image
                    loading="lazy"
                    style={{ display: 'inline' }}
                    width={26}
                    height={26}
                    src="/assets/Icons/linkedin.svg"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
